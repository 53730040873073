import React, { useState } from "react";
import styled from "styled-components";
import { FaComments } from "react-icons/fa";
import CONFIG from "../config/config";

// Styled-components per il design migliorato
const ChatIconContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const ChatCircle = styled.div`
  background-color: #007bff;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const ChatContainer = styled.div`
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 350px;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? "translateY(0)" : "translateY(20px)")};
`;

const ChatHeader = styled.div`
  background-color: #005289;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
      justify-content: center;
    align-items: center;
    display: flex;
`;

const ChatBody = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
`;

const ChatFooter = styled.div`
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
`;

const MessageInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

// Nuovo stile per i messaggi
const Message = styled.div`
  margin: 8px 0;
  padding: 10px 15px;
  border-radius: 18px;
  background-color: ${({ isUser }) => (isUser ? '#007bff' : '#f1f1f1')};
  color: ${({ isUser }) => (isUser ? 'white' : 'black')};
  text-align: ${({ isUser }) => (isUser ? 'right' : 'left')};
  max-width: 70%;
  align-self: ${({ isUser }) => (isUser ? 'flex-end' : 'flex-start')};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  word-wrap: break-word;  /* Aggiunta per spezzare le parole lunghe */
`;
const ChatLogo = styled.img`
  margin-right: 10px;
  width: 30px;
  height: 100%;
`;
const ChatWidget = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sessionId, setSessionId] = useState(null); // Memorizza il session_id

  // Funzione per inviare il messaggio al server
  const sendMessage = async () => {
    if (inputValue.trim() === '') return; // Evita l'invio di messaggi vuoti

    // Aggiungi il messaggio dell'utente all'interfaccia
    const userMessage = { isUser: true, text: inputValue };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      // Crea il payload per il body della richiesta
      const requestBody = sessionId
        ? { text: inputValue, session_id: sessionId } // Includi session_id nei messaggi successivi
        : { text: inputValue }; // Al primo messaggio, non includere session_id

      // Invio del messaggio al backend
      const response = await fetch(`${CONFIG.API_BASE_URL}/api/chatReact/send-message/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (!sessionId && data.session_id) {
        // Salva il session_id alla prima risposta
        setSessionId(data.session_id);
      }

      // Aggiungi i messaggi del bot all'interfaccia (gestisce array di risposte)
      const botMessages = data.response.map((text) => ({
        isUser: false,
        text,
      }));
      setMessages((prevMessages) => [...prevMessages, ...botMessages]);
    } catch (error) {
      console.error('Error sending message:', error);
    }

    setInputValue(''); // Resetta il campo di input
  };

    // Funzione per inviare il messaggio al server
    const OpenConversation = async () => {
     
    
        // Aggiungi il messaggio dell'utente all'interfaccia
        //const userMessage = { isUser: true, text: "inizio conversazione Web" };
        //setMessages((prevMessages) => [...prevMessages, userMessage]);
    
        try {
          // Crea il payload per il body della richiesta
          /*const requestBody = sessionId
            ? { text: inputValue, session_id: sessionId } // Includi session_id nei messaggi successivi
            : { text: "Ciao" }; // Al primo messaggio, non includere session_id
    
          // Invio del messaggio al backend
          const response = await fetch('http://localhost:3000/api/chatReact/send-message/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
         
          const data = await response.json();
    
          if (!sessionId && data.session_id) {
            // Salva il session_id alla prima risposta
            setSessionId(data.session_id);
          }
          */
          // Aggiungi i messaggi del bot all'interfaccia (gestisce array di risposte)
          const botMessages ={
            isUser: false,
            text:"Benvenuto nell'assistente virtuale Marazzato, come posso aiutarti oggi? Puoi scegliere dicendo, richiesta acqua potabile e richiesta spurgo",
          }
          setMessages((prevMessages) => [...prevMessages, botMessages]);
        } catch (error) {
          console.error('Error sending message:', error);
        }
    
        setInputValue(''); // Resetta il campo di input
      };

  return (
    <>
      <ChatIconContainer>
      <ChatCircle onClick={() => {
  setIsChatOpen(!isChatOpen);
  if (messages.length === 0) {  // Controlla se messages è vuoto
    OpenConversation();  // Chiama OpenConversation solo se non ci sono messaggi
  }
}}>
          <FaComments />
        </ChatCircle>
      </ChatIconContainer>

      <ChatContainer isOpen={isChatOpen}>
        <ChatHeader>
        <ChatLogo src="logo-bianco.png" alt="logo" /> Assistente AI Marazzato</ChatHeader>
        <ChatBody>
          {messages.map((msg, index) => (
            <Message key={index} isUser={msg.isUser}>
              {msg.text}
            </Message>
          ))}
        </ChatBody>
        <ChatFooter>
          <MessageInput
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()} // Invio con "Enter"
            placeholder="Scrivi il tuo messaggio"
          />
          <SendButton onClick={sendMessage}>Invia</SendButton>
        </ChatFooter>
      </ChatContainer>
    </>
  );
};

export default ChatWidget;
