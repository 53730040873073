import React, { useState, useEffect } from 'react';
import './CategoriesPage.css';
import Loader from '../component/Loader';
import { useNavigate } from 'react-router-dom';
import CONFIG from '../config/config';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: '', description: '' });
  const [editIndex, setEditIndex] = useState(-1);
  const [loading, setLoading] = useState(true); // Stato per gestire il caricamento
  const navigate= useNavigate();
  // Carica le categorie al caricamento del componente
  useEffect(() => {
    fetchCategories();
  }, []);

  // Funzione per ottenere tutte le categorie dal backend
  const fetchCategories = async () => {
    setLoading(true); // Inizia il caricamento
    try {
    /*  const response = await fetch('http://localhost:3000/api/categories');
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        console.error("Errore nel recupero delle categorie:", response.status);
        alert("Errore nel recupero delle categorie");
      }*/
              // Effettua la richiesta con le credenziali e il base URL dal file di configurazione
              const response = await fetch(`${CONFIG.API_BASE_URL}/api/categories`, {
                method: 'GET',
                credentials: 'include', // Includi i cookie e altre credenziali
              });
      
              // Se lo stato della risposta è 403, reindirizza alla pagina di login
              if (response.status === 403) {
                navigate('/login'); // Sostituisci '/login' con il percorso desiderato
                return;
              }
      
              if (response.ok) {
                const data = await response.json();
                setCategories(data); // Aggiorna lo stato con le categorie recuperate
              } else {
                console.error("Errore nel recupero delle categorie:", response.status);
                alert("Errore nel recupero delle categorie"); // Mostra un messaggio d'errore
              }
    } catch (error) {
      console.error("Errore di rete nel recupero delle categorie:", error);
      alert("Errore di rete nel recupero delle categorie");
    }finally{
        setLoading(false); // Inizia il caricamento
    }
  };

  // Funzione per aggiungere una nuova categoria
  const handleAddCategory = async () => {
    if (newCategory.name.trim() && newCategory.description.trim()) {
      try {
       /* const response = await fetch('http://localhost:3000/api/categories', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newCategory),
        });
        if (response.ok) {
          fetchCategories(); // Ricarica le categorie dopo l'aggiunta
          setNewCategory({ name: '', description: '' });
          alert("Categoria aggiunta con successo");
        } else {
          console.error("Errore nell'aggiunta della categoria:", response.status);
          alert("Errore nell'aggiunta della categoria");
        }*/
          const response = await fetch(`${CONFIG.API_BASE_URL}/api/categories`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include', // Includi i cookie e altre credenziali
            body: JSON.stringify(newCategory),
          });
    
          // Se lo stato della risposta è 403, reindirizza alla pagina di login
          if (response.status === 403) {
            navigate('/login'); // Sostituisci '/login' con il percorso desiderato
            return;
          }
    
          if (response.ok) {
            const data = await response.json();
            console.log("Categoria aggiunta:", data);
            // Reindirizza o aggiorna lo stato dopo l'aggiunta della categoria
          } else {
            console.error("Errore nell'aggiunta della categoria:", response.status);
            alert("Errore nell'aggiunta della categoria");
          }
      } catch (error) {
        console.error("Errore di rete nell'aggiunta della categoria:", error);
        alert("Errore di rete della categoria");
      }
    }
  };

  // Funzione per eliminare una categoria
  const handleDeleteCategory = async (id) => {
    try {
   /*   const response = await fetch(`http://localhost:3000/api/categories/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchCategories(); // Ricarica le categorie dopo la cancellazione
        alert("Categoria eliminata con successo");
      } else {
        console.error("Errore nella cancellazione della categoria:", response.status);
        alert("Errore nella cancellazione della categoria");
      }*/
        const response = await fetch(`${CONFIG.API_BASE_URL}/api/categories/${id}`, {
            method: 'DELETE',
            credentials: 'include', // Includi i cookie e altre credenziali
          });
      
          // Se lo stato della risposta è 403, reindirizza alla pagina di login
          if (response.status === 403) {
            navigate('/login'); // Sostituisci '/login' con il percorso desiderato
            return;
          }
      
          if (response.ok) {
            fetchCategories(); // Ricarica le categorie dopo la cancellazione
            alert("Categoria eliminata con successo");
          } else {
            console.error("Errore nella cancellazione della categoria:", response.status);
            alert("Errore nella cancellazione della categoria");
          }
    } catch (error) {
      console.error("Errore di rete nella cancellazione della categoria:", error);
      alert("Errore di rete nella cancellazione della categoria");
    }
  };

  // Funzione per salvare la modifica di una categoria
  const handleSaveCategory = async () => {
    if (editIndex !== -1) {
      const updatedCategory = categories[editIndex];
      try {
       /* const response = await fetch(`http://localhost:3000/api/categories/${updatedCategory.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedCategory),
        });
        if (response.ok) {
          fetchCategories(); // Ricarica le categorie dopo la modifica
          setEditIndex(-1);
        } else {
          console.error("Errore nell'aggiornamento della categoria:", response.status);
        }*/
          const response = await fetch(`${CONFIG.API_BASE_URL}/api/categories/${updatedCategory.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include', // Includi i cookie e altre credenziali
            body: JSON.stringify(updatedCategory),
          });
    
          // Se lo stato della risposta è 403, reindirizza alla pagina di login
          if (response.status === 403) {
            navigate('/login'); // Sostituisci '/login' con il percorso desiderato
            return;
          }
    
          if (response.ok) {
            fetchCategories(); // Ricarica le categorie dopo la modifica
            setEditIndex(-1); // Resetta l'indice di modifica
          } else {
            console.error("Errore nell'aggiornamento della categoria:", response.status);
          }
      } catch (error) {
        console.error("Errore di rete nell'aggiornamento della categoria:", error);
      }
    }
  };

  // Funzione per gestire le modifiche agli input
  const handleInputChange = (event, index, field) => {
    let updatedCategories = [...categories];
    updatedCategories[index][field] = event.target.value;
    setCategories(updatedCategories);
  };
  if (loading) return <Loader></Loader>;
  return (
    <div className="category-container">
      <h1 className="category-title">Gestione Categorie</h1>
      <table className="category-table">
        <thead>
          <tr>
            <th className="category-header">Nome Categoria</th>
            <th className="category-header">Descrizione</th>
            <th className="category-header">Azioni</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={category.id} className="category-row">
              <td className="category-cell">
                {editIndex === index ? (
                  <input
                    type="text"
                    value={category.name}
                    onChange={(e) => handleInputChange(e, index, 'name')}
                    className="category-input"
                  />
                ) : (
                  category.name
                )}
              </td>
              <td className="category-cell">
                {editIndex === index ? (
                  <input
                    type="text"
                    value={category.description}
                    onChange={(e) => handleInputChange(e, index, 'description')}
                    className="category-input"
                  />
                ) : (
                  category.description
                )}
              </td>
              <td className="category-cell">
                {editIndex === index ? (
                  <button className="category-button category-save" onClick={handleSaveCategory}>Salva</button>
                ) : (
                  <button className="category-button category-edit" onClick={() => setEditIndex(index)}>Modifica</button>
                )}
                <button className="category-button category-delete" onClick={() => handleDeleteCategory(category.id)}>Cancella</button>
              </td>
            </tr>
          ))}
          <tr className="category-row category-add-row">
            <td className="category-cell">
              <input
                type="text"
                value={newCategory.name}
                onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                placeholder="Nuova Categoria"
                className="category-input"
              />
            </td>
            <td className="category-cell">
              <input
                type="text"
                value={newCategory.description}
                onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
                placeholder="Descrizione"
                className="category-input"
              />
            </td>
            <td className="category-cell">
              <button className="category-button category-add" onClick={handleAddCategory}>Aggiungi</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CategoriesPage;
