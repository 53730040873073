import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ConversationDetailPage.css'; // Stili CSS aggiornati per la pagina
import Loader from '../../component/Loader';
import CONFIG from '../../config/config';

const ConversationDetailPage = () => {
  const { id } = useParams(); // Ottieni l'ID della conversazione dall'URL
  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate= useNavigate();

  useEffect(() => {
    // Funzione per recuperare la conversazione dall'API
    const fetchConversation = async () => {
      try {
     /*   const response = await fetch(`http://localhost:3000/api/conversations/${id}`);
        const data = await response.json();
        if (data.status === 'success') {
          console.log(data.conversation);
          setConversation(data.conversation);
        } else {
          setError('Conversazione non trovata.');
        }*/
               // Effettua la richiesta con le credenziali
               const response = await fetch(`${CONFIG.API_BASE_URL}/api/conversations/${id}`, {
                method: 'GET',
                credentials: 'include', // Includi i cookie e altre credenziali
              });
      
              // Se lo stato della risposta è 403, reindirizza alla pagina di login
              if (response.status === 403) {
                navigate('/login'); // Sostituisci '/login' con il percorso desiderato
                return;
              }
      
              const data = await response.json(); // Converti la risposta in formato JSON
      
              if (data.status === 'success') {
                //console.log(data.conversation);
                setConversation(data.conversation); // Aggiorna lo stato con la conversazione recuperata
              } else {
                setError('Conversazione non trovata.');
              }
      } catch (err) {
        setError('Errore durante il recupero della conversazione.');
      } finally {
        setLoading(false);
      }
    };

    fetchConversation();
  }, [id]);

  if (loading) return <Loader></Loader>;
  if (error) return <div className="error">{error}</div>;

  // Contenuto della conversazione
  const conversationContent = conversation.conversation_content
    ? JSON.parse(conversation.conversation_content)
    : [];

  // Controlla se esistono i parametri e li parsa, altrimenti restituisce un oggetto vuoto
  const parameters = conversation.parameters
    ? (() => {
        try {
          return JSON.parse(conversation.parameters);
        } catch (err) {
          console.error("Errore nel parsing dei parametri JSON:", err);
          return {}; // Ritorna un oggetto vuoto in caso di errore nel parsing
        }
      })()
    : {};

  return (
    <div className="conversation-page">
      <div className="conversation-header">
        <h1>Conversazione</h1>
        <p>ID conversazione: {id} </p>
        <p>Orario Inizio Chiamata  {new Date(conversation.start_time).toLocaleString()}</p>
        <p><strong>Numero di Telefono:</strong> {conversation.phone_number || 'Non disponibile'}</p>
      </div>

      <div className="conversation-container">
        <div className="chat-container">
          <div className="chat-box">
            {conversationContent.map((interaction, index) => (
              <div
                key={index}
                className={`message ${interaction.role === 'bot' ? 'bot-message' : 'user-message'}`}
              >
                <div className="message-content">{interaction.message}</div>
                <div className="message-timestamp">
                  {new Date(interaction.timestamp).toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="parameters-container">
          <h2>Parametri Estratti</h2>
          <div className="parameters-box">
         {/* {parameters && Object.keys(parameters).length > 0 ? (  // Controlla se parameters è un oggetto valido
            <div className="parameters-box">
              {Object.keys(parameters).map((key) => (
                <div key={key} className="parameter-item">
                  <strong>{key}:</strong> {parameters[key]}
                </div>
              ))}
            </div>
          ) : (
            <p>Nessun parametro disponibile.</p>
          )} */}

{parameters && Object.keys(parameters).length > 0 ? (  // Controlla se parameters è un oggetto valido
              <div className="parameters-box">
                {Object.keys(parameters).map((key) => (
                  <div key={key} className="parameter-item">
                    <strong>{key}:</strong> 
                    {/* Check if the value is an object and convert to string */}
                    {typeof parameters[key] === 'object'
                      ? JSON.stringify(parameters[key]) 
                      : parameters[key]}
                  </div>
                ))}
              </div>
            ) : (
              <p>Nessun parametro disponibile.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationDetailPage;
