import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CONFIG from '../config/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importa le icone per l'hamburger

// Stili per la navbar
const NavbarContainer = styled.nav`
  background-color: white;
  border-bottom: 2px solid #ccc;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LogoContainer = styled.div`
  img {
    height: 70px;
    width: auto;
    @media (max-width: 768px) {
      height: 60px;
    }
  }
`;

const HamburgerIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 2rem;
    cursor: pointer;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  gap: 2rem;

  li {
    a {
      text-decoration: none;
      color: #333;
      font-weight: bold;
      transition: color 0.3s ease;

      &:hover {
        color: #555;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin:0;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
      padding: 0;
    
    border-top: 2px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 99;
    transition: all 0.3s ease;
    max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
    overflow: hidden;

    li {
      width: 100%;
      text-align: left;
      padding: 0.5rem 0;
    }
  }
`;

const LogoutButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 1rem;
  }
`;

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Stato per gestire l'hamburger menu
  const location = useLocation();
  const navigate = useNavigate();

  const checkSession = async () => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}/check-session`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Errore durante la verifica della sessione:', error);
    }
  };

  useEffect(() => {
    checkSession();
  }, [location]);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}/logout/`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        console.error('Errore durante il logout:', await response.json());
      }
    } catch (error) {
      console.error('Errore durante la richiesta di logout:', error);
    }
  };

  // Funzione per gestire l'apertura/chiusura dell'hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <NavbarContainer>
      <LogoContainer>
        {isAuthenticated && (
          <a href="/">
            <img
              src="https://gruppomarazzato.com/wp-content/uploads/2018/07/LogoOrizzblu_no-claim-4.png"
              alt="Logo"
            />
          </a>
        )}
      </LogoContainer>
      
      {isAuthenticated && (
        <>
          <HamburgerIcon onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />} {/* Mostra l'icona dell'hamburger o la X */}
          </HamburgerIcon>
          
          <NavLinks isOpen={isMenuOpen}>
            <li><a href="/">Home</a></li>
            <li><a href="/emails">Emails</a></li>
            <li><a href="/calls">Centralino</a></li>
            <li><a href="/cronjobs">Cronjobs</a></li>
            <li>
              <LogoutButton onClick={handleLogout}>Disconnetti</LogoutButton>
            </li>
          </NavLinks>
        </>
      )}
    </NavbarContainer>
  );
};

export default Navbar;
