import React, { useState } from 'react';
import styles from  './LinksTable.css';  // Importiamo gli stili aggiornati
import Navbar from '../bar/Navbar';
import Loader from '../component/Loader';
import CONFIG from '../config/config';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ChatWidget from '../chat/ChatWidget';

// Styled Components
const ImagesSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Permette di andare su più righe se necessario */
`;

const ImageContainer = styled.div`
  flex: 1; /* Le immagini occuperanno spazi uguali */
  min-width: 200px; /* Imposta una larghezza minima per evitare che si ridimensionino troppo */

  img {
    width: 100%; /* L'immagine occuperà tutta la larghezza del container */
    height: auto;
    border-radius: 8px; /* Aggiunge bordi arrotondati alle immagini */
  }

  /* Media query per schermi piccoli (mobile) */
  @media (max-width: 768px) {
    margin-bottom: 20px; /* Aggiungi uno spazio tra le immagini in modalità mobile */
  }
`;
const ImageContainer2 = styled.div`
  flex: 1; /* Le immagini occuperanno spazi uguali */
  min-width: 200px; /* Imposta una larghezza minima per evitare che si ridimensionino troppo */

  img {
    width: 300px; /* L'immagine occuperà tutta la larghezza del container */
    height: auto;
    border-radius: 8px; /* Aggiunge bordi arrotondati alle immagini */
  }

  /* Media query per schermi piccoli (mobile) */
  @media (max-width: 768px) {
   width: 100%; /* L'immagine occuperà tutta la larghezza del container */
    margin-bottom: 20px; /* Aggiungi uno spazio tra le immagini in modalità mobile */
  }
`;


const LinksTable = () => {
    const [loading,setLoading]= useState(true);
    const navigate= useNavigate();


    const checkSession = async () => {
        try {
          // Effettua una richiesta a /check-session/
          const response = await fetch(`${CONFIG.API_BASE_URL}/check-session/`, {
            method: 'GET',
            credentials: 'include', // Invia cookie e credenziali
          });
  
          if (response.ok) {
            // Se la sessione è valida, imposta loading a false per mostrare la pagina
            setLoading(false);
          } else if (response.status === 403) {
            // Se riceviamo un 403, reindirizza alla pagina di login
            navigate('/login');
          } else {
            // Altri errori
            }
        } catch (err) {
          console.error('Errore durante la verifica della sessione:', err);
          
        }
      };

      checkSession();

    if (loading) return <Loader></Loader>;
    return (
        <div className={styles.homeContainer}>
          <div className={styles.heroSection}>
            <h1>Benvenuto nel mio Sito dei Progetti</h1>
            <p>
            In questo portale troverai i progetti che sto sviluppando.
            </p>
          </div>
          
          <div className={styles.projectsSection}>
            <div className={styles.projectCard}>
              <h2>Progetto Email</h2>
              <p>
              Questo progetto non è presente sul sito pubblico, ma ho iniziato lo sviluppo sulla mia macchina locale salvo poi per interromperlo. Sono stati eseguiti diversi test,
        tra cui il recupero delle email da Gmail, la loro categorizzazione e la
        visualizzazione su una pagina web.
              </p>
              <p><strong>Status del Progetto:</strong> Proof of Concept.</p> 
            </div>
    
            <div className={styles.projectCard}>
              <h2>Progetto Centralino</h2>
              <p>
              Il progetto <strong>Centralino</strong> si occupa di analizzare le
        chiamate effettuate dai clienti verso il centralino aziendale.
        L'assistente AI gestisce, trascrive e analizza le conversazioni,
        consentendo agli operatori di gestire le richieste senza dover rispondere
        fisicamente. Per testare il sistema, puoi chiamare il numero:{" "}
        <strong>+1 (213) 224-3114</strong>.
              </p>
              <a href="/calls" className={styles.linkButton}>Vai al Progetto Centralino</a>
            </div>
            <p><strong>Status del Progetto:</strong> Proof of Concept.</p> 
          </div>

          <div className={styles.futureSection}>
            <h2>App Android </h2>
            <p>
            Ho sviluppato un'app che include la lista delle conversazioni dell'assistente AI
      e una chat per interagire con lui. Sarà uno strumento molto utile per i test
      e potrà essere utilizzato per gestire l'assistente AI quando sarà operativo.
      Inoltre, sarà un esempio per lo sviluppo di altre app che possano supportare
      i lavoratori in azienda.
            </p>
            <p><strong>Status del Progetto:</strong> Proof of Concept.</p> 
          </div>
             {/* Sezione immagini */}
      <ImagesSection>
        <ImageContainer>
          <img src="marazzato0.jpg" alt="Progetto 1" />
        </ImageContainer>
        <ImageContainer>
        <img src="marazzato1.jpg" alt="Progetto 1" />
        </ImageContainer>
        <ImageContainer>
        <img src="marazzato2.jpg" alt="Progetto 1" />
        </ImageContainer>
        <ImageContainer>
        <img src="marazzato3.jpg" alt="Progetto 1" />
        </ImageContainer>
        <ImageContainer>
        <img src="marazzato4.jpg" alt="Progetto 1" />
    
        </ImageContainer>
      </ImagesSection>
          <div className={styles.futureSection}>
            <h2>Assistente AI Whatsapp</h2>
            <p>
            Ho sviluppato le API per l'integrazione dell'assistente AI su WhatsApp e ho
      condotto i primi test. Durante questi test, ho individuato delle funzionalità
      che potrebbero essere molto utili al team di Marketing. In attesa che sia operativa la funzionalita dei messaggi Whatsapp su odoo una possibile idea
      è quella di creare un piccolo gestionale per inviare messaggi in bulk con
      bottoni interattivi, raccogliendo dati utili per le loro analisi dagli utenti.
            </p>
            <p><strong>Status del Progetto:</strong> Proof of Concept.</p> 
          </div>
          <ImagesSection>
        <ImageContainer2>
          <img src="marazzato5.jpg" alt="Progetto 1" />
        </ImageContainer2>
        <ImageContainer2>
        <img src="marazzato 6.jpg" alt="Progetto 1" />
        </ImageContainer2>
    
      </ImagesSection>
      <ChatWidget></ChatWidget>
        </div>
      );
    };

export default LinksTable;
