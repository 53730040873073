import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import CONFIG from '../config/config';
import { useNavigate } from 'react-router-dom';


const clientId = '870456748440-mf96i8o22goqfac1di0t9k6f1e5jmhsq.apps.googleusercontent.com';

const LoginPage = () => {
    const navigate= useNavigate();
  const onSuccess = (credentialResponse) => {
    console.log('Login Success:', credentialResponse);
    handleLogin(credentialResponse.credential);
  };


  const handleLogin = async (token) => {
    try {
        console.log("questo e il token",token);
      const response = await fetch(`${CONFIG.API_BASE_URL}/login`, {
        method: 'POST',
        credentials: 'include',  // Necessario per inviare e ricevere i cookie
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Login successful', data);
        navigate('/');
    
      } else {
        const errorData = await response.json();
        console.error('Login failed:', errorData.detail);
      }
    } catch (error) {
      console.error('Network error', error);
    }
  };

  const onFailure = () => {
    console.error('Login Failed');
  };

  return (
<GoogleOAuthProvider clientId={clientId}>
  <div 
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh', // Altezza del contenitore per centrare verticalmente
      width: '100vw' // Larghezza del contenitore per centrare orizzontalmente
    }} 
    className="login-container"
  >
    <div 
      style={{
        width: '500px', 
        background: 'rgba(255, 255, 255, 0.9)', // Sfondo semi-trasparente
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Effetto ombra
        borderRadius: '15px', // Angoli arrotondati
        padding: '30px', // Spazio interno
        textAlign: 'center', // Centrare il contenuto
        height:'200px',
        
      }} 
      className="login-box"
    >
      <h2>Accedi con Google</h2>
      <div style={{alignItems:'center'}}><GoogleLogin style={{width:'90%'}} onSuccess={onSuccess} onError={onFailure} /></div>
    </div>
  </div>
</GoogleOAuthProvider>
  );
};

export default LoginPage;
