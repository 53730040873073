import React, { useState } from 'react';
import './GetEmailsPage.css';  // Assicurati di creare un file CSS per stilizzare il componente
import { BallTriangle } from 'react-loader-spinner'; // Importa il loader
import CONFIG from '../config/config';
import { useNavigate } from 'react-router-dom';
const GetEmailsPage = () => {
  const [startDate, setStartDate] = useState(''); // Stato per la data di inizio
  const [endDate, setEndDate] = useState('');     // Stato per la data di fine
  const [emails, setEmails] = useState([]);       // Stato per memorizzare le email
  const [message, setMessage] = useState('');     // Stato per eventuali messaggi di errore/successo
  const [caricamento,setCaricamento]= useState(false);
  const navigate= useNavigate();
  // Funzione per gestire il click del bottone
  const fetchEmails = async () => {
    if (!startDate || !endDate) {
      setMessage('Inserisci entrambe le date.');
      return;
    }

    try {
   /*   setCaricamento(true);
      const response = await fetch(
        `http://localhost:3000/mails/get-emails-by-data-from-gmail?start_date=${startDate}&end_date=${endDate}`
      );
      const data = await response.json();
      
      if (data.status === 'success') {
        setEmails(data.emails);
        console.log(data.emails);
        setMessage(data.message || 'Email trovate con successo!');
      } else {
        setMessage(data.message || 'Errore nella richiesta.');
      }*/
        setCaricamento(true);
        const response = await fetch(
          `${CONFIG.API_BASE_URL}/mails/get-emails-by-data-from-gmail?start_date=${startDate}&end_date=${endDate}`, 
          {
            method: 'GET',
            credentials: 'include', // Includi i cookie e altre credenziali
          }
        );
    
        // Se lo stato della risposta è 403, reindirizza alla pagina di login
        if (response.status === 403) {
          navigate('/login'); // Sostituisci '/login' con il percorso desiderato
          return;
        }
    
        const data = await response.json();
    
        if (data.status === 'success') {
          setEmails(data.emails); // Aggiorna lo stato con le email recuperate
          console.log(data.emails);
          setMessage(data.message || 'Email trovate con successo!');
        } else {
          setMessage(data.message || 'Errore nella richiesta.');
        }
    } catch (error) {
      setMessage('Errore nella richiesta: ' + error.message);
    }finally{
      setCaricamento(false);
    }
  };

  return (
    <div className="get-emails-container">
      <h2>Ricerca Email per Data</h2>
      <div className="date-inputs">
        <div>
          <label htmlFor="start-date">Data Inizio:</label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="end-date">Data Fine:</label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <button onClick={fetchEmails} className="fetch-button">Cerca Email</button>
      
      {/* Mostra messaggi */}
      {message && <div className="message">{message}</div>}

      {/* Mostra le email trovate */}
      {emails.length > 0 && !caricamento && (
        <div className="emails-list">
          <h3>Email Trovate:</h3>
          <ul>
            {emails.map((email, index) => (
              <li key={index}>
                <strong>Oggetto:</strong> {email.title} <br />
                <strong>Mittente:</strong> {email.sender_email} <br />
                <strong>Data Ricezione:</strong> {email.received_date}
              </li>
            ))}
          </ul>
        </div>
      )}


         {caricamento && <div style={{
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '28vh',
              //backgroundColor: '#f9f9f9'
            }}>
            <BallTriangle
              height={100} // Altezza del loader
              width={100} // Larghezza del loader
              color="#007BFF" // Colore del loader
              ariaLabel="loading-indicator"
            />
            <p style={{
              marginTop: '20px', 
              fontSize: '1.5rem', 
              color: '#007BFF', 
              fontWeight: 'bold'
            }}>
              Caricamento...
            </p>
          </div>}
    </div>
  );
};

export default GetEmailsPage;
