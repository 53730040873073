// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LinksTable from './linksPage/linksFile';
import EmailsTable from './EmailPage/EmailPage';
import Navbar from './bar/Navbar';
import JobStatusPage from './cronjobs/JobStatusPage';
import ConversationDetailPage from './CallsPage/conversation/ConversationDetailPage';
import ConversationsPage from './CallsPage/ConversationsPage';
import CategoriesPage from './categories/CategoriesPage';
import GetEmailsPage from './getMails/GetEmailsPage';
import LoginPage from './Login/loginPage';
import GmailViewer from './mails2.0/GmailViewer';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './mails2.0/AuthContext';
import EmailDetailPage from './mails2.0/EmailDetailPage';

function App() {
  return (
    <AuthProvider>
         <GoogleOAuthProvider clientId="870456748440-kvgj0u09h6j1gvtaa1oni8488hat41pe.apps.googleusercontent.com">
          
    <Router>
      <Navbar /> {/* Aggiungiamo la Navbar in tutte le pagine */}
      <Routes>
        <Route path="/" element={<LinksTable />} /> {/* Home: Tabella dei link */}

        <Route path="/calls" element={<ConversationsPage />} /> {/* Pagina delle email */}
        <Route path="/conversation/:id" element={<ConversationDetailPage />} />
        <Route path="/cronjobs" element={<JobStatusPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/get-mails" element={<GetEmailsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/emails" element={<GmailViewer />} />
        <Route path="/email/:id" element={<EmailDetailPage />} />
      </Routes>
    </Router>
    </GoogleOAuthProvider>
    </AuthProvider>
  );
}

export default App;
