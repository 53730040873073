import React, { createContext, useState } from 'react';

// Crea il contesto
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [emails, setEmails] = useState([]); // Stato per la lista di email
    const [selectedEmail, setSelectedEmail] = useState(null); // Stato per l'email selezionata

    return (
        <AuthContext.Provider value={{ accessToken, setAccessToken, emails, setEmails, selectedEmail, setSelectedEmail }}>
            {children}
        </AuthContext.Provider>
    );
};
