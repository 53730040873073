import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import CONFIG from '../config/config';
import { useNavigate } from 'react-router-dom';
import Loader from '../component/Loader';

// Styled Components
const JobStatusContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 20px auto;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Spinner animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
// Loader styled component
const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  color: #003366;
  text-align: left;
  margin-bottom: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.thead`
  background-color: #f1f1f1;
  color: #333;
  text-align: left;

  th {
    padding: 12px 15px;
    font-weight: bold;
    font-size: 14px;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #e9ecef;
  }
`;

const TableData = styled.td`
  padding: 12px 15px;
  font-size: 14px;
  text-align: left;


`;
const TableDataId = styled.td`
  padding: 12px 15px;
  font-size: 14px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 11px; /* Riduce la dimensione del font su mobile */
    padding: 8px 10px; /* Eventualmente riduce anche il padding per un look più compatto */
  }
`;

const StatusActive = styled.span`
  color: green;
  font-weight: bold;
`;

const StatusInactive = styled.span`
  color: red;
  font-weight: bold;
`;

const ManualRunSection = styled.div`
  text-align: center;
  margin-top: 30px;

  h2 {
    font-size: 1.5rem;
    color: #003366;
    margin-bottom: 15px;
  }
`;

const Button = styled.button`
  background-color: ${(props) => (props.disabled ? '#ccc' : '#007bff')};
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : '#0056b3')};
  }
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 15px;
  font-size: 1rem;
`;

const Error = styled.div`
  color: red;
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100px;
`;


const JobStatusPage = () => {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [manualRunSuccess, setManualRunSuccess] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${CONFIG.API_BASE_URL}/jobs`, {
          method: 'GET',
          credentials: 'include',
        });

        if (response.status === 403) {
          navigate('/login');
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setJobs(data);
        } else {
          console.error("Errore nel recupero dei lavori:", response.status);
          alert("Errore nel recupero dei lavori");
        }
      } catch (err) {
        setError('Errore durante il recupero dei job.');
      }
    };

    fetchJobs();

    const interval = setInterval(fetchJobs, 10000);
    return () => clearInterval(interval);
  }, [navigate]);

  const handleManualRun = async () => {
    setLoading(true);
    setManualRunSuccess(false);
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}/manual-run/all-jobs`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        setManualRunSuccess(true);
        setTimeout(() => setManualRunSuccess(false), 3000);
      } else {
        console.error("Errore durante l'esecuzione manuale dei job:", response.status);
        alert("Errore durante l'esecuzione manuale dei job");
      }
    } catch (error) {
      console.error('Errore durante la richiesta di run manuale:', error);
      alert('Errore durante la richiesta di run manuale');
    } finally {
      setLoading(false);
    }
  };

  if (error) return <Error>{error}</Error>;

  return (
    <JobStatusContainer>
      <Title>Stato dei Job</Title>
      <StyledTable>
        <TableHeader>
          <tr>
            <th>Job ID</th>
            <th>Prossima Esecuzione</th>
            <th>Tempo Rimanente (s)</th>
            <th>Stato</th>
          </tr>
        </TableHeader>
        <tbody>
          {jobs.map((job) => (
            <TableRow key={job.job_id}>
              <TableDataId>{job.job_id}</TableDataId>
              <TableData>{new Date(job.next_run_time).toLocaleString()}</TableData>
              <TableData>{job.tempo_rimanente.toFixed(2)}</TableData>
              <TableData>
                {job.tempo_rimanente < 0 ? (
                  <StatusInactive>
                    <FontAwesomeIcon icon={faTimesCircle} /> Inattivo
                  </StatusInactive>
                ) : (
                  <StatusActive>
                    <FontAwesomeIcon icon={faCheckCircle} /> Attivo
                  </StatusActive>
                )}
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>

      <ManualRunSection>
        <h2>Esegui Manualmente Tutti i Job</h2><br></br>
        <Button onClick={handleManualRun} disabled={loading}>
          {loading ? (
            <LoaderContainer>
              <p>Estrazione in corso...</p>
              <Spinner />
            </LoaderContainer>
          ) : (
            "Esegui Job Manualmente"
          )}
        </Button>
        {manualRunSuccess && <SuccessMessage>Job eseguiti manualmente con successo!</SuccessMessage>}
      </ManualRunSection>
    </JobStatusContainer>
  );
};

export default JobStatusPage;
