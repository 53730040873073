import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faPhone, faCheckCircle, faTimesCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Loader from '../component/Loader';
import CONFIG from '../config/config';

// Numero di righe per pagina
const ROWS_PER_PAGE = 10;

// Stili
const TableContainer = styled.div`

  background-color: #f9fafb; /* Grigio chiaro */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 768px) {
 
     width: 100%;
  }
`;

const Title = styled.h1`
  color: #1e3a8a;
  text-align: center;
  margin-bottom: 2rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #ddd;

    @media (max-width: 768px) {
     
       width: 100%;
    }
  }

  th {
    background-color: #e5e7eb; /* Grigio più chiaro */
    font-weight: bold;
    color: #333;
  }

  td {
    color: #333;
  }

  .status-active {
    color: green;
  }

  .status-inactive {
    color: red;
  }

  .actions-column {
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  }
`;

const ExpandedRow = styled.tr`
  td {
    padding: 1rem;
    background-color: #f1f5f9; /* Colore di sfondo per le righe espanse */
    border-top: 1px solid #ddd;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 1rem;

  span {
    color: #333;
  }
`;

const PaginationButton = styled.button`
  background-color: #1e3a8a; /* Colore blu */
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3b82f6; /* Blu più chiaro */
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  background-color: #1e3a8a;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3b82f6; /* Blu più chiaro */
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

// Mostra/nasconde le colonne aggiuntive su mobile
const MobileHiddenColumn = styled.td`
  @media (max-width: 768px) {
    display: none;
  }
`;

const DesktopHiddenColumn = styled.th`
  @media (min-width: 768px) {
    display: none;
  }
`;


const ExpandButton = styled(Button)`
  @media (min-width: 769px) {
    display: none; /* Nascondi il pulsante espandi su schermi desktop */
  }
`;

const ConversationsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [conversations, setConversations] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]); // Stato per le righe espanse
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Controlla se è mobile
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
    // Aggiungi un event listener al montaggio del componente per ascoltare i cambiamenti di larghezza
    useEffect(() => {
      window.addEventListener('resize', handleResize); // Ascolta l'evento di resize
  
      // Cleanup: rimuovi l'event listener quando il componente si smonta
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // L'array vuoto significa che l'effetto si esegue solo al montaggio e smontaggio
  

  const indexOfLastRow = currentPage * ROWS_PER_PAGE;
  const indexOfFirstRow = indexOfLastRow - ROWS_PER_PAGE;
  const currentRows = conversations.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(conversations.length / ROWS_PER_PAGE);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const fetchConversations = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}/api/conversations`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.status === 403) {
        navigate('/login');
        return;
      }

      const data = await response.json();
      if (data.status === 'success') {
        setConversations(data.conversations);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Errore durante il recupero delle conversazioni.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  const handleConversationClick = (conversationId) => {
    navigate(`/conversation/${conversationId}`);
  };

  const toggleRow = (conversationId) => {
    setExpandedRows((prev) =>
      prev.includes(conversationId)
        ? prev.filter((id) => id !== conversationId) // Rimuovi dall'espansione
        : [...prev, conversationId] // Aggiungi all'espansione
    );
  };

  if (loading) return <Loader />;
  if (error) return <p>{error}</p>;

  return (
    <TableContainer>
      <Title>Lista Conversazioni</Title>
      <Table>
        <thead>
          <tr>
            <th>Conversation ID</th>
            <th>Tipo</th>
            <th>Durata</th>
            {!isMobile && (
              <>
                <th>Data Inizio</th> {/* Colonna mostrata su mobile */}
                <th>Conteggio Iterazioni</th> {/* Colonna mostrata su mobile */}
                <th>Chat Estratta</th> {/* Colonna mostrata su mobile */}
                <th>Azioni</th>
              </>
            )}

           {isMobile && (
              <>
           <MobileHiddenColumn>Data Inizio</MobileHiddenColumn> {/* Nascondi su mobile */}
            <MobileHiddenColumn>Conteggio Iterazioni</MobileHiddenColumn> {/* Nascondi su mobile */}
            <MobileHiddenColumn>Chat Estratta</MobileHiddenColumn> {/* Nascondi su mobile */}
            <MobileHiddenColumn>Azioni</MobileHiddenColumn> 
            </>)}
         
          <DesktopHiddenColumn>  <th>Espandi</th> {/* Visibile solo su mobile */} </DesktopHiddenColumn>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((conversation) => (
            <>
              <tr key={conversation.id}>
                <td>{conversation.id}</td>
                <td>
                  {conversation.conversation_type === 'TEXT' ? (
                    <FontAwesomeIcon icon={faCommentDots} title="Text" />
                  ) : conversation.conversation_type === 'AUDIO' ? (
                    <FontAwesomeIcon icon={faPhone} title="Audio" />
                  ) : (
                    conversation.conversation_type
                  )}
                </td>
                <td>{parseInt(conversation.duration, 10)}s</td>
                {/* Colonne aggiuntive visibili solo su desktop */}
                <MobileHiddenColumn>{new Date(conversation.start_time).toLocaleString()}</MobileHiddenColumn>
                <MobileHiddenColumn>{conversation.interaction_count}</MobileHiddenColumn>
                <MobileHiddenColumn>
                  {conversation.chat_extracted ? (
                    <span className="status-active">
                      Si <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                  ) : (
                    <span className="status-inactive">
                      No <FontAwesomeIcon icon={faTimesCircle} />
                    </span>
                  )}
                </MobileHiddenColumn>
                <MobileHiddenColumn>
                  <Button onClick={() => handleConversationClick(conversation.id)}>
                    Vai a Conversazione
                  </Button>
                  </MobileHiddenColumn>
                <td>
                  <ExpandButton onClick={() => toggleRow(conversation.id)}>
                    {expandedRows.includes(conversation.id) ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </ExpandButton>
                </td>
              </tr>
              {expandedRows.includes(conversation.id) && (
                <ExpandedRow>
                  <td colSpan="5">
                    <strong>Data Inizio:</strong> {new Date(conversation.start_time).toLocaleString()}<br />
                    <strong>Conteggio Iterazioni:</strong> {conversation.interaction_count}<br />
                    <strong>Chat Estratta:</strong>{' '}
                    {conversation.chat_extracted ? (
                      <span className="status-active">
                        Si <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    ) : (
                      <span className="status-inactive">
                        No <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    )}
                 
                     <Button style={{marginTop:10}} onClick={() => handleConversationClick(conversation.id)}>
                    Vai a Conversazione
                  </Button>
                  </td>
                </ExpandedRow>
              )}
            </>
          ))}
        </tbody>
      </Table>

      <PaginationContainer>
        <PaginationButton onClick={prevPage} disabled={currentPage === 1}>
          Precedente
        </PaginationButton>
        <span>Pagina {currentPage} di {totalPages}</span>
        <PaginationButton onClick={nextPage} disabled={currentPage === totalPages}>
          Successiva
        </PaginationButton>
      </PaginationContainer>
    </TableContainer>
  );
};

export default ConversationsPage;
