import React from 'react';
import { BallTriangle } from 'react-loader-spinner'; // Importa il loader

const Loader = () => {
  return (
    <div style={styles.container}>
      <BallTriangle
        height={100} // Altezza del loader
        width={100} // Larghezza del loader
        color="#007BFF" // Colore del loader
        ariaLabel="loading-indicator"
      />
      <p style={styles.text}>Caricamento...</p>
    </div>
  );
};

// Stili personalizzati per centrare il loader e il testo
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Sfondo leggermente opaco
  },
  text: {
    fontSize: '18px',
    color: '#007BFF',
    marginTop: '20px',
  },
};

export default Loader;
