import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext } from './AuthContext';
import { useLocation } from 'react-router-dom';

const EmailDetailPage = () => {
   // const { selectedEmail } = useContext(AuthContext); // Ottieni l'email selezionata dal contesto
   const location = useLocation(); // Usa useLocation per accedere allo stato
   const { email,accessToken } = location.state; // Ottieni l'email dallo stato
   
    const [category, setCategory] = useState(null); // Stato per la categoria restituita
    const [loading, setLoading] = useState(false); // Stato per indicare se la richiesta è in corso
    const [html, setHtml] = useState(false); // Stato per indicare se la richiesta è in corso
    const [testo, setTesto] = useState(false); // Stato per indicare se la richiesta è in corso
    const apiKey = "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"; // Sostituisci con la tua chiave OpenAI


    useEffect(()=>{
        const plainPart = findAllPartsByMimeType(email.body.payload.parts || [], 'text/plain');
        const htmlPart = findAllPartsByMimeType(email.body.payload.parts|| [], 'text/html');
        console.log("html part",htmlPart);
        console.log("plain part",plainPart);
        setHtml(htmlPart);
        setTesto(plainPart);
        extractTextEmailBody(email);
    },[])
    const classifyEmail = async () => {
        let testo= extractTextEmailBody(email);
        console.log("testo che sto inviando al prompt");
        if (email) {
            const prompt = `
        Classifica questa email in una o più delle seguenti categorie:
        - Appalti
        - Ambiente
        - Tecnologia (sia informatica che ricerca e sviluppo)
        - Sicurezza
        - Compliance
        - Legale
        - Amministrazione/Finanza
        - Personale
        
        Rispondi con le categorie più appropriate, seguito da una sola proposta di oggetto accattivante per ogni categoria individuata. Infine, fornisci un breve riassunto del contenuto dell'email. Ecco il formato di risposta:
        
        Categorie: [Categoria1, Categoria2, ...]

        Oggetti:
        - Appalti: "Oggetto accattivante per la categoria Appalti"
        - Ambiente: "Oggetto accattivante per la categoria Ambiente"
        - Tecnologia: "Oggetto accattivante per la categoria Tecnologia"
        - Sicurezza: "Oggetto accattivante per la categoria Sicurezza"
        - Compliance: "Oggetto accattivante per la categoria Compliance"
        - Legale: "Oggetto accattivante per la categoria Legale"
        - Amministrazione/Finanza: "Oggetto accattivante per la categoria Amministrazione/Finanza"
        - Personale: "Oggetto accattivante per la categoria Personale"
        
        Riassunto: Scrivi un breve riassunto del contenuto dell'email indicando anche i link.

        Qui di seguito c'è il testo di un'email:
        
        ${testo}
            `;
    
            try {
                setLoading(true); // Inizia il caricamento
                const response = await fetch("https://api.openai.com/v1/chat/completions", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`
                    },
                    body: JSON.stringify({
                        model: "gpt-3.5-turbo",  // Cambi il modello a GPT-3.5
                        messages: [
                            { role: "system", content: "Sei un assistente che classifica le email." },
                            { role: "user", content: prompt }
                        ],
                        max_tokens: 4096,  // Riduci il limite dei token per adattarsi al massimo supportato da GPT-3.5
                        temperature: 0.2,
                    })
                });
    
                const data = await response.json();
                console.log(data);
               const result = data.choices[0].message.content; // Ottieni la categoria e altri dati dal risultato di OpenAI
               console.log("risultato openai",result); 
               setCategory(result); // Imposta la categoria nello stato
            } catch (error) {
                console.error("Errore nella classificazione dell'email:", error);
            } finally {
                setLoading(false); // Fine del caricamento, anche in caso di errore
            }
        }
    };

   

    
    const findAllPartsByMimeType = (parts, mimeType) => {
        let result = [];
    
        for (let part of parts) {
            // Se la parte corrisponde al mimeType richiesto, aggiungila ai risultati
            if (part.mimeType === mimeType) {
                result.push(part);
            }
    
            // Se la parte ha sotto-parti, chiama la funzione ricorsivamente
            if (part.parts) {
                result = result.concat(findAllPartsByMimeType(part.parts, mimeType));
            }
        }
    
        return result;
    };


    const extractEmailBody = (email) => {
        let body = '';
        
        // Helper per decodificare una stringa base64 sicura
        const decodeBase64 = (data) => {
            try {
                return decodeURIComponent(
                    escape(window.atob(data.replace(/-/g, '+').replace(/_/g, '/')))
                );
            } catch (error) {
                console.error('Errore nella decodifica Base64:', error);
                return null;
            }
        };
    
        // Controllo sul corpo principale dell'email
        if (email.body && email.body.size > 0 && email.body && email.body.data) {
            // Decodifica il corpo principale (se disponibile)
            body = decodeBase64(email.body.data);
        }
    


        const plainPart = findAllPartsByMimeType(email.body.payload.parts || [], 'text/plain');
        const htmlPart = findAllPartsByMimeType(email.body.payload.parts|| [], 'text/html');
        console.log(htmlPart);
      //  setHtml(htmlPart);
        //setTesto(plainPart);
        console.log(plainPart);
        // Se non abbiamo trovato il corpo nel payload principale, controlliamo le parti (multipart)
        if (htmlPart && htmlPart[0].body.data) {
            // Prova a cercare una parte di tipo 'text/html'
            //const htmlPart = email.body.payload.parts.find(part => part.mimeType === 'text/html');
           // if (htmlPart && htmlPart.body && htmlPart.body.data) {
                body = decodeBase64(htmlPart[0].body.data);
            //}
    
            // Se non c'è HTML, prova a cercare il plain text ('text/plain')
            if (!body) {
                //const plainPart = email.body.payload.parts.find(part => part.mimeType === 'text/plain');
                if (plainPart && plainPart[0].body.data) {
                    body = decodeBase64(plainPart[0].body.data);
                }
            }
        }
    
        // Se il corpo non è ancora disponibile, restituisci un messaggio di fallback
        return body || 'Nessun contenuto disponibile';
    };
    const extractTextEmailBody = (email) => {
        let body = '';
        
        // Helper per decodificare una stringa base64 sicura
        const decodeBase64text = (data) => {
            try {
                return decodeURIComponent(
                    escape(window.atob(data.replace(/-/g, '+').replace(/_/g, '/')))
                );
            } catch (error) {
                console.error('Errore nella decodifica Base64:', error);
                return null;
            }
        };
        const plainPart = findAllPartsByMimeType(email.body.payload.parts || [], 'text/plain');
        if (plainPart && plainPart[0].body.data) {
            body = decodeBase64text(plainPart[0].body.data);
        }

        console.log("questo e il testo ",body);
    
        // Se il corpo non è ancora disponibile, restituisci un messaggio di fallback
        return body || 'Nessun contenuto disponibile';
    };

    const downloadAttachment = async (emailId, attachment) => {
        try {
            const response = await fetch(
                `https://www.googleapis.com/gmail/v1/users/me/messages/${emailId}/attachments/${attachment.body.attachmentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Error downloading attachment');
            }

            // Recupera i dati binari correttamente
            const data = await response.json();
            const decodedData = window.atob(data.data.replace(/-/g, '+').replace(/_/g, '/'));
            const byteArray = new Uint8Array(decodedData.length);
            
            for (let i = 0; i < decodedData.length; i++) {
                byteArray[i] = decodedData.charCodeAt(i);
            }

            const blob = new Blob([byteArray], { type: attachment.mimeType || 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', attachment.filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading attachment:', error);
        }
    };

    return (
        <Container>
            <EmailHeader>
                <h2>{email?.subject}</h2>
                <p>{email?.from}</p>
                <p>{email?.EmailDetailPage}</p>
            </EmailHeader>
            <Body dangerouslySetInnerHTML={{ __html: extractEmailBody(email) }} />
            <AttachmentContainer>
                    {email.body.payload.parts
                      ?.filter((part) => part.filename)
                      .map((attachment) => (
                        <AttachmentItem key={attachment.partId}>
                          <AttachmentName>{attachment.filename}</AttachmentName>
                          <Button onClick={() => downloadAttachment(email.id, attachment)}>
                            Download
                          </Button>
                        </AttachmentItem>
                      ))}
                  </AttachmentContainer>
            <Button onClick={classifyEmail} disabled={loading}>
                {loading ? 'Classificando...' : 'Classifica con OpenAI'}
            </Button>

            {category && (
                <ResultContainer>
                    <h3>Categoria assegnata:</h3>
                    <p>{category}</p>
                </ResultContainer>
            )}
        </Container>
    );
};

export default EmailDetailPage;

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
`;
const AttachmentContainer = styled.div`
  margin-top: 20px;
`;

const AttachmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
`;

const AttachmentName = styled.p`
  margin: 0;
`;

const EmailHeader = styled.div`
  margin-bottom: 20px;
`;

const Body = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #002b5c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #004080;
  }

  &:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
`;

const ResultContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #ccc;
  white-space: pre-wrap;
`;
