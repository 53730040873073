import React, { useEffect, useState } from 'react';
import './EmailsTable.css'; // Assicurati di avere un file CSS per stilizzare la tabella
import Loader from '../component/Loader';
import GmailConnection from './component/GmailConnectionSection/GmailConnectionSection';
import Modal from 'react-modal'; // Libreria per i popup (assicurati di averla installata: npm install react-modal)
import { BallTriangle } from 'react-loader-spinner';
import CONFIG from '../config/config';
import { useNavigate } from 'react-router-dom';
// Numero di righe per pagina
const ROWS_PER_PAGE = 10;

Modal.setAppElement('#root'); // Imposta l'elemento root per l'accessibilità

const EmailsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [emails, setEmails] = useState([]); // Stato per memorizzare le email
  const [loading, setLoading] = useState(true); // Stato per gestire il caricamento
  const [error, setError] = useState(null); // Stato per gestire gli errori
  const [modalIsOpen, setModalIsOpen] = useState(false); // Stato per gestire il popup
  const [modalContent, setModalContent] = useState(''); // Contenuto del popup
  const [analyzing, setAnalyzing] = useState(false); // Stato per mostrare il loader nel popup
  const navigate = useNavigate();
  // Calcola l'indice della prima e ultima riga da mostrare
  const indexOfLastRow = currentPage * ROWS_PER_PAGE;
  const indexOfFirstRow = indexOfLastRow - ROWS_PER_PAGE;
  const currentRows = emails.slice(indexOfFirstRow, indexOfLastRow); // Ottieni le righe correnti

  // Calcola il numero totale di pagine
  const totalPages = Math.ceil(emails.length / ROWS_PER_PAGE);

  // Funzione per passare alla pagina successiva
  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  // Funzione per tornare alla pagina precedente
  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const fetchEmails = async () => {
    setLoading(true); // Inizia il caricamento
    try {
     /* const response = await fetch('http://localhost:3000/mails/get-emails-from-server',{  credentials: 'include', method: 'GET' }); // Assicurati che l'endpoint sia corretto
      const data = await response.json(); // Converti la risposta in formato JSON
      console.log(data);
      if (data.status === 'success') {
        setEmails(data.emails); // Aggiorna lo stato con le email recuperate
      } else {
        setError(data.message); // Gestisci il messaggio di errore
      } */
             // Effettua la richiesta con le credenziali e il base URL dal file di configurazione
             const response = await fetch(`${CONFIG.API_BASE_URL}/mails/get-emails-from-server`, {
              method: 'GET',
              credentials: 'include', // Includi i cookie e altre credenziali
            });
    
            // Se lo stato della risposta è 403, reindirizza alla pagina di login
            if (response.status === 403) {
              navigate('/login'); // Sostituisci '/login' con il percorso desiderato
              return;
            }
    
            const data = await response.json(); // Converti la risposta in formato JSON
            console.log(data);
    
            if (data.status === 'success') {
              setEmails(data.emails); // Aggiorna lo stato con le email recuperate
            } else {
              setError(data.message); // Gestisci il messaggio di errore
            }
    } catch (err) {
      setError('Errore durante il recupero delle email.'); // Gestisci l'errore
    } finally {
      setLoading(false); // Fine del caricamento
    }
  };

  // Funzione per analizzare un'email tramite API
  const analyzeEmail = async (emailId) => {
    setModalIsOpen(true); // Apri il popup
    setAnalyzing(true); // Mostra il caricamento
    try {
    /*  const response = await fetch(`http://localhost:3000/mails/analyze-email/${emailId}`);
      const data = await response.json();
      if (response.ok) {
        // Mostra il risultato dell'analisi
        setModalContent(`Risultato Analisi: ${data.categories}`);
      } else {
        setModalContent(`Errore durante l'analisi: ${data.detail}`);
      }*/
        const response = await fetch(`${CONFIG.API_BASE_URL}/mails/analyze-email/${emailId}`, {
          method: 'GET',
          credentials: 'include', // Includi i cookie e altre credenziali
        });
    
        // Se lo stato della risposta è 403, reindirizza alla pagina di login
        if (response.status === 403) {
          navigate('/login'); // Sostituisci '/login' con il percorso desiderato
          return;
        }
    
        const data = await response.json();
    
        if (response.ok) {
          // Mostra il risultato dell'analisi
          setModalContent(`Risultato Analisi: ${data.categories}`);
        } else {
          setModalContent(`Errore durante l'analisi: ${data.detail}`);
        }
    } catch (err) {
      setModalContent('Errore durante l\'analisi dell\'email.');
    } finally {
      setAnalyzing(false); // Nascondi il caricamento
    }
  };

  // useEffect per eseguire il fetch delle email quando il componente si monta
  useEffect(() => {
    fetchEmails();
  }, []);

  // Se ci sono errori o è in fase di caricamento, visualizza un messaggio appropriato
  if (loading) return <Loader />;
  if (error) return <p>{error}</p>;

  return (
    <>
      <GmailConnection />
      <div className="emails-table-container">
        <h1 style={{ color: '#1e3a8a', textAlign: 'center' }}>Emails List</h1>
        <table className="emails-table">
          <thead>
            <tr>
              <th>Email ID</th>
              <th>Sender</th>
              <th>Subject</th>
              
              <th>Analyzed</th>
              <th>Category</th>
              <th>Already Sent</th>
              <th>Actions</th> {/* Colonna per i bottoni */}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((email) => (
              <tr key={email.email_id}>
                <td>{email.email_id}</td>
                <td>{email.sender}</td>
                <td>{email.subject}</td>
                
                <td>{email.analyzed ? 'Yes' : 'No'}</td>
                <td>{email.category}</td>
                <td>{email.already_sent ? 'Yes' : 'No'}</td>
                <td>
                  <div className="actions-column">
                    <button className="btn btn-blue">Cambia Categoria</button>
                    <button className="btn btn-green" onClick={() => analyzeEmail(email.google_id)}>Analizza</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Paginazione */}
        <div className="pagination-container">
          <button onClick={prevPage} disabled={currentPage === 1} className="emails-table-button">
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={nextPage} disabled={currentPage === totalPages} className="emails-table-button">
            Next
          </button>
        </div>
      </div>

      {/* Popup per mostrare il risultato dell'analisi */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Risultato Analisi"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '30px',
            borderRadius: '10px',
            border: '1px solid #ccc',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        {analyzing ? (
          <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '28vh',
            width:'50vh',
            //backgroundColor: '#f9f9f9'
          }}>
          <BallTriangle
            height={100} // Altezza del loader
            width={100} // Larghezza del loader
            color="#007BFF" // Colore del loader
            ariaLabel="loading-indicator"
          />
          <p style={{
            marginTop: '20px', 
            fontSize: '1.5rem', 
            color: '#007BFF', 
            fontWeight: 'bold'
          }}>
            Caricamento...
          </p>
        </div>
        ) : (
          <div style={ { width:'50vh'}}>
            <h2>Risultato Analisi</h2>
            <p>{modalContent}</p>
            <button onClick={() => setModalIsOpen(false)} className="emails-table-button">
              Chiudi
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default EmailsTable;
