import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { AuthContext } from './AuthContext';
import styled from 'styled-components';

const GmailViewer = () => {
    const { accessToken, setAccessToken, emails, setEmails, setSelectedEmail } = useContext(AuthContext); // Usa il contesto
    const navigate = useNavigate();

    const login = useGoogleLogin({
        onSuccess: (response) => {
            console.log('Access Token:', response.access_token);
            setAccessToken(response.access_token);
        },
        onError: (errorResponse) => {
            console.error('Login Failed:', errorResponse);
        },
        scope: 'https://www.googleapis.com/auth/gmail.readonly',
        ux_mode: 'popup',
    });

    useEffect(() => {
        if (accessToken && emails.length === 0) {
            fetchEmails();
        }
    }, [accessToken]);

    const fetchEmails = async () => {
        if (!accessToken) return;

        try {
            const response = await fetch(
                'https://www.googleapis.com/gmail/v1/users/me/messages',
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Error fetching emails');
            }

            const data = await response.json();
            const messageIds = data.messages.map((message) => message.id);

            const emailPromises = messageIds.map((id) =>
                fetch(`https://www.googleapis.com/gmail/v1/users/me/messages/${id}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }).then((res) => res.json())
            );

            const emailData = await Promise.all(emailPromises);
            const mailToServer = extractEmailInfo(emailData);
            console.log(mailToServer);
            setEmails(mailToServer);
        } catch (error) {
            console.error('Error fetching emails:', error);
        }
    };

    const extractEmailInfo = (emailData) => {
        let extractedEmails = [];
        emailData.forEach(email => {
            let emailInfo = {};
            let headers = email.payload.headers;

            emailInfo.id = email.id;
            emailInfo.body = email;
            headers.forEach(header => {
                if (header.name === "From") emailInfo.from = header.value;
                if (header.name === "Date") emailInfo.date = header.value;
                if (header.name === "Subject") emailInfo.subject = header.value;
            });

            extractedEmails.push(emailInfo);
        });
        return extractedEmails;
    };

    const handleViewEmail = (email) => {
        setSelectedEmail(email); // Salviamo l'email selezionata nel contesto
        //navigate(`/email/${email.id}`);
       // navigate(`/email/${email.id}`, { state: { email } });
       navigate(`/email/${email.id}`, { state: { email, accessToken } });
    };

    return (
        <Container>
            <Title>Gmail Viewer</Title>
            <StatusContainer>
                <StatusText connected={!!accessToken}>
                    Status: {accessToken ? 'Connected' : 'Not Connected'}
                </StatusText>
                <br />
                {!accessToken ? (
                    <Button onClick={login}>Login with Google</Button>
                ) : (
                    <Button onClick={fetchEmails}>Fetch Emails</Button>
                )}
            </StatusContainer>
            <EmailList>
                {emails.map((email) => (
                    <EmailItem key={email.id}>
                        <EmailHeader>
                            <Sender>{email.from}</Sender>
                            <Date>{email.date}</Date>
                        </EmailHeader>
                        <Subject>{email.subject}</Subject>
                        <Button onClick={() => handleViewEmail(email)}>
                            Visualizza
                        </Button>
                    </EmailItem>
                ))}
            </EmailList>
        </Container>
    );
};

export default GmailViewer;

const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const Title = styled.h1`
  color: #002b5c;
  text-align: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #002b5c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    background-color: #004080;
  }
`;

const StatusContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const StatusText = styled.span`
  color: ${(props) => (props.connected ? '#28a745' : '#dc3545')};
  font-size: 16px;
  font-weight: bold;
`;

const EmailList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const EmailItem = styled.li`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const EmailHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Sender = styled.span`
  font-weight: bold;
  color: #007bff;
`;

const Date = styled.span`
  color: #888;
  font-size: 14px;
`;

const Subject = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;
